/* 기본 css 시작 */
section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 0;
}
/* 기본 css 끝 */

@value makerBlack, #ff4e00, #ddd, #008aff from './colors.css';
@value tiny from './sizes.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background:#faf2f2;
  padding-bottom:70px;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  margin: 20px;
  font-size: 2.875rem;
}

  a:link { color: black; text-decoration: none;}
  a:visited { color: black; text-decoration: none;}
  a:hover { color: blue; text-decoration: underline;}

*{
  margin:0; padding:0;
  font-size:15px; 
  line-height:1.3;
}
ul{list-style:none;}


h2 {
  margin: 10px;
}

.tabmenu{ 
  max-width:100%; 
  margin: 0 auto; 
  position:relative; 
  /*border-radius: 15px 15px 0px 0px / 15px 15px 0px 0px;*/
}
.tabmenu button{
  display:inline-block;
  width:16.66%; 
  float:left;  
  text-align:center; 
  background :#ffffff;
  line-height:60px;
  border : 0px solid #f9f9f9;
}
button label{
  display:block;
  width:100%;
  vertical-align:middle; 
  height:60px;
  line-height:20px;
}
.tabmenu input{display:none;}
.tabCon{
  display:none; 
  text-align:left; 
  padding: 20px;
  position:absolute; 
  left:0; top:40px; 
  box-sizing: border-box; 
  border : 5px solid #f9f9f9;
}
.tabmenu input:checked ~ label{
  background:#ccc;
}
.tabmenu input:checked ~ .tabCon{
  display:block;
}

footer{ position:fixed; 
  left:0px; 
  bottom:0px; 
  height:60px; 
  width:100%; 
  color: white; 
  }

header {
  height: 30px;
  padding: 1rem;  
  color:#abaaaa;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background:#faf2f2;
}

.topButton{
  display:inline-block;
  width:16.66%; 
  float:left;  
  text-align:center; 
  background :#ffffff;
  line-height:60px;
  border : 0px solid #f9f9f9;
}

/* 이상하게 이게 안먹고 프리보드에 있는 css를 먹네*/

h3 {
  margin-bottom: 40px;
}


.delBtn {  
  position: relative;
  float: right;
  top: -60px;
  right: 10px;
  border: 0px solid #000;
  background-color: #faf2f2;
}

.adminDelBtn {  
  position: relative;
  float: right;
  top: -38px;
  right: 0px;
  border: 0px solid #000;
  background-color: #faf2f2;
}

.adminFreeBoardDelBtn {  
  position: relative;
  float: right;
  top: -20px;
  right: 0px;
  border: 0px solid #000;
  background-color: #faf2f2;
}

.writeRedBtn {  
  background-color: #f78287;
  color: #fff;
  margin: 10px;
  padding: 5px 10px;
}

.writeAdminBtn {  
  position: absolute;
  top: 22%;
  right: 15%;
  background-color: #f78287;
  border: 0;
  z-index: 1000;
  color: #fff;
  margin: 30px;
  border-radius: 30px;
  padding: 15px 20px;
}

.grayBtn {  
  background-color: #d8d4d4;
  color: #000;
  margin: 10px;
  padding: 5px 10px;
}

.linkBtn {  
  background-color: #742626;
  color: #fff;
  border: 0;
  width: 94%;
  margin: 12px;
  padding: 5px 10px;
}
.link2Btn {  
  background-color: #553566;
  color: #fff;
  border: 0;
  width: 94%;
  margin: 12px;
  padding: 5px 10px;
}

.box {
  padding:10px;
  height:57px;
  vertical-align: sub;
}

.buttons {
  margin-top: 20px;
  text-align: center;
}

.write,
.cancel {
  font-size: 0.875rem;
  padding: 5px 10px;
  margin: 0 8px;
}

.write {
  background-color: #383838;
  color: #fff;
}

.cancel {
  background-color: #ddd;
  color: makerBlack;
}

.writeBtn {
  background-color: #f78287;
  color: #fff;
  margin: 10px;
  padding: 5px 10px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form > input {
  font-size: 0.75rem;
  height: 36px;
  padding: 0 5px;
  margin-bottom: 8px;
}

.form > select {
  font-size: 0.75rem;
  height: 36px;
  padding: 0 5px;
  margin-bottom: 8px;
}

.form > input[type='quiz']:focus,
.form > input[type='answer']:focus {
  border: 1px solid #f78287;
}

.form .row > select {
  font-size: 0.75rem;
  height: 36px;
  padding: 0 5px;
  margin-bottom: 8px;
}

.shotBtn {
  position: relative;
  float: right;
  top: 0px;
  right: 0px;
  border: 0px solid #000;
  background-color: #faf2f2;
}

.shotTopBtn {
  position: relative;
  float: right;
  top: -38px;
  right: 0px;
  border: 0px solid #000;
  background-color: #faf2f2;
}

.copySucc {
  color: #cc149b;
}

.toast {
  top: 80px;
  left: 30px;
  height: 70px;
}

.mybox {
  width: 90%;
  padding-left:5%;
}


.row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.rowComment {
  display: block;
  flex-basis: 300px;
}

.row > span {
  display: block;
  flex-basis: 100px;
}

.email {
  line-height: 36px;
}

.row > input[type='text'],
.email {
  height: 36px;
  padding: 0 5px;
}

.row > input[type='text'],
.message {
  flex-basis: 90%;
  border: 1px solid #ddd;
}

.row > input[type='text'],
.nametapShot {
  flex-basis: 90%;
  border: 1px solid #ddd;
}

.select {
  flex-basis: 300px;
  border: 1px solid #ddd;  
  height: 40px;
  padding: 10px 5px;
}

.row > input[type='text']:focus,
.message:focus {
  border-color: #ff4e00;
}

.message {
  height: 120px;
  padding: 10px 5px;
}

.nametapShot {
}

.save,
.logout {
  font-weight: bold;
  color: #ff4e00;
  margin-top: 20px;
  display: block;
  padding: 5px 10px;
  border: 1px solid #ff4e00;
}

.save {
  color: #008aff;
  border-color: #008aff;
}

.row:last-child {
  justify-content: space-around;
}

@media (max-width: tiny) {
  .box {
    width: 100%;
  }

  .row > span {
    flex-basis: 80px;
  }

  .row > input[type='text'],
  .message {
    flex-basis: calc(100% - 80px);
  }
}



.bg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  width: 320px;
  min-height: 500px;
  padding: 60px 25px;
  border-radius: 8px;
  text-align: center;
}

.title {
  line-height: 2.5rem;
  color: #f95c62;
  margin-bottom: 50px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form > input {
  font-size: 0.75rem;
  height: 36px;
  padding: 0 5px;
  margin-bottom: 8px;
}

.form > input[type='email']:focus,
.form > input[type='password']:focus {
  border: 1px solid #f78287;
}

.login {
  cursor: pointer;
  background-color: #f78287;
  color: #000;
}

.newAccount {
  background-color: #008aff;
}

.account {
  cursor: pointer;
  font-size: 0.875rem;
  color: #000;
  transition: color 0.5s;
}

.account:hover {
  color: #000;
}

.googleLogin {
  font-size: 0.75rem;
  width: 100%;
  height: 36px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.icon {
  margin-right: 5px;
}

@media (max-width: tiny) {
  .container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .googleLogin {
    margin-bottom: 100px;
  }
}


.writer {
  padding-left: 6px;
  padding-right: 10px;
  float: right;
}

.date {
  padding-left: 6px;
  padding-right: 10px;
  float: right;
  color: gray;
}

.board_title {
  display: inline-block;
  width: 900px;
  height: 22px;
  font-size: 1.25rem;
  padding-left: 5px;
  color: blue;
}
@media(max-width:720px) { 
	.board_title { 
	  display: inline-block;
	  width: 180px;
	  height: 22px;
	  white-space: nowrap;
	  overflow: hidden;
	  padding-left: 5px;
	  text-overflow: ellipsis;
	} 
}
@media(max-width:320px) { 
	.board_title { 
	  display: inline-block;
	  width: 120px;
	  height: 22px;
	  white-space: nowrap;
	  overflow: hidden;
	  padding-left: 5px;
	  text-overflow: ellipsis;
	} 
}

.content {
  display: inline-block;
  width: 900px;
  height: 19px;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 5px;
  text-overflow: ellipsis;
}
@media(max-width:720px) { 
	.content { 
	  display: inline-block;
	  width: 180px;
	  height: 19px;
	  white-space: nowrap;
	  overflow: hidden;
	  padding-left: 5px;
	  text-overflow: ellipsis;
	} 
}
@media(max-width:320px) { 
	.content { 
	  display: inline-block;
	  width: 120px;
	  height: 19px;
	  white-space: nowrap;
	  overflow: hidden;
	  padding-left: 5px;
	  text-overflow: ellipsis;
	} 
}

.board_title_noimg {
  display: inline-block;
  width: 1000px;
  height: 22px;
  font-size: 1.25rem;
  padding-left: 5px;
  color: blue;
}
@media(max-width:720px) { 
	.board_title_noimg { 
	  display: inline-block;
	  width: 300px;
	  height: 22px;
	  white-space: nowrap;
	  overflow: hidden;
	  padding-left: 5px;
	  text-overflow: ellipsis;
	} 
}
@media(max-width:320px) { 
	.board_title_noimg { 
	  display: inline-block;
	  width: 200px;
	  height: 22px;
	  white-space: nowrap;
	  overflow: hidden;
	  padding-left: 5px;
	  text-overflow: ellipsis;
	} 
}

.content_noimg {
  display: inline-block;
  width: 1000px;
  height: 19px;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 5px;
  text-overflow: ellipsis;
}
@media(max-width:720px) { 
	.content_noimg { 
	  display: inline-block;
	  width: 300px;
	  height: 19px;
	  white-space: nowrap;
	  overflow: hidden;
	  padding-left: 5px;
	  text-overflow: ellipsis;
	} 
}
@media(max-width:320px) { 
	.content_noimg { 
	  display: inline-block;
	  width: 200px;
	  height: 19px;
	  white-space: nowrap;
	  overflow: hidden;
	  padding-left: 5px;
	  text-overflow: ellipsis;
	} 
}

.read_title {
  font-size: 1.25rem;
  padding-left: 5px;
  padding-bottom: 20px;
  color: blue;
}
.read_content {
  width: 95%;
  padding: 20px 5px 20px;
}
.read_content pre{
    /*background-color: #E6E6FA;*/
    padding:10px;
    overflow: auto;
    white-space: pre-wrap; /* pre tag내에 word wrap */
}  


.content pre {
  white-space: pre-wrap;
}


#input-file {
  display:none;
}
.input-file {
  display:none;
}

.file-upload-container {
    width: 1000px;
    height: 1000px;
    overflow: hidden;
    background: #3F51B5;
    user-select: none;
    transition: all 150ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    text-align: center;
    color: white;
    line-height: 100px;
    font-weight: 0;
    font-size: 50px;
}

.board_list {
   float: left;
}

.board_list2 {
	height:65px;
    float: left;

    text-overflow: ellipsis;
    overflow: hidden;
}

.eye_read_no {
    position: relative;
	float: right;
	color: #3943cc;
	border: 0px solid #000;
	font-size:0.875rem;
}

.hidden {
	display:none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.pagnation {
    text-align: center;
    height: 70px;
}

.margin-top-30 {
    margin-top: 30px;
}

.pagnation span, ol {
    display: inline-block;
    vertical-align: middle;
}

ol, ul {
    list-style: none;
}

.pagnation li {
    display: table-cell;
    padding: 0 10px;
}

.pagnation li.current, .pagnation li.current:hover {
	border: 1px solid #00a0ee;
    font-weight: bold;
    color: #00a0ee;
    background: #fff;
    cursor: default;
}

ol {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
}

.imgBorder {
	border: 2px solid #000;
}

.useNick {
	padding-left:100px;
	padding-bottom:10px;
	color: red;
}

.banner {
	border: 1px solid #aaa8a8;
	padding : 30px;
	margin : 10px;
	border-radius: 10px 10px 10px 10px / 10px 10px 10px 10px;
}

.coopang {
	border: 0px solid #000;
	text-align : center;
	margin : 10px;
}

.answer_layout {
	padding: 20px 15px;
}

.answer_board {
	background-color: #ddd;
	padding: 20px 15px;
}

.comments_title {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 20px;

}

.comment_box {
    font-size: .875rem;
    padding: 10px 5px;
    position: relative;
}

.comment_img {
}
.comment_name {
    font-weight: 700;
    padding-left: 5px;
}
.comment_content {
    margin-top: 5px;
    margin-left: 10px;
	width: 95%;
}
.comment_content pre{
    background-color: #fff;
    padding:10px;
    overflow: auto;
	white-space: pre-wrap;
}  
.comment_date {
    font-size: .75rem;
    padding-left: 8px;
    color: #999;
}
.comment_btnBox {
    position: absolute;
    top: 10px;
    right: 0;
    font-size: .875rem;
}
.commentbtnBox>button {
    font-size: .75rem;
    margin: 0 3px;
    cursor: pointer;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 6px;
    padding: 0;
}

.comments_inputBox {
    margin-top: 20px;
    position: relative;
}
.comments_input {
    width: 100%;
    height: 40px;
    padding: 0 0 5px 5px;
}
.comments_button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: .875rem;
    background-color: #383838;
    color: #fff;
    padding: 5px 8px;
}

.edit {
	border: 0px solid #000;	
    background-color: #ddd;
    padding: 0 4px;
}

.moneyButton {
	border: 1px solid #f88aad;	
	margin-left : 20px;
	background: none;
	color:#f88aad;
    border-radius: 5px;
    font-weight: bold;
    text-transform: uppercase;
}

.dollarIcon {
	padding: 100px;	
}

.react-icons {
  vertical-align: middle;
}

.free_react-icons {
  vertical-align: top;
}

.rcontent {
  word-break:break-all;
}

.push_alarm {
	width:32%;
}
.push_alarm2 {
	width:36%;
}


.hide input{
	color:transparent;
	border: 0;
	outline: transparent;
    background-color: transparent;
}

.longBtn {
  margin-top: 20px;
  padding: 10px;
  width: 80%;
  color: red;
  border-color: #9f9f9f;
}

.black_overlay{
	display: none;
	position: absolute;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	background-color: black;
	z-index:1001;
	-moz-opacity: 0.8;
	opacity:.80;
	filter: alpha(opacity=80);
}
.white_content {
	display: block;
	position: absolute;
	top: 32%;
	left: 21%;
	width: 50%;
	height: 30%;
	padding: 16px;
	border: 1px solid pink;
	background-color: white;
	z-index:1002;
	overflow: auto;
}


.hide_content {
	display: none;
	position: absolute;
	top: 32%;
	left: 21%;
	width: 50%;
	height: 30%;
	padding: 16px;
	border: 1px solid pink;
	background-color: white;
	z-index:1002;
	overflow: auto;
}

.billMap {
	margin-bottom: 20px;
}

.saveDelBtn {  
  position: relative;
  float: right;
  top: -70px;
  right: -10px;
  background-color: #f78287;
  color: #fff;
  margin: 10px;
  padding: 5px 10px;
}

.messageBtn { 
  position: relative;
  float: right;
  top: -70px;
  right: -10px;
  margin: 10px;
  padding: 5px 10px;
}


.moneyForm {
  text-align: center;
  display: float;
  flex-direction: column;
}

.moneyForm > input {
  font-size: 0.75rem;
  height: 36px;
  padding: 0 5px;
  margin-bottom: 8px;
  width: 15%;
}

.moneyForm > select {
  font-size: 0.75rem;
  height: 36px;
  padding: 0 5px;
  margin-bottom: 8px;
}

.moneyForm > input[type='quiz']:focus,
.moneyForm > input[type='answer']:focus {
  border: 1px solid #f78287;
}

.moneyForm .row > select {
  font-size: 0.75rem;
  height: 36px;
  padding: 0 5px;
  margin-bottom: 8px;
}

.moneyTable {
	border : 1px solid #f9f9f9;
	width: 100%;
}

table.type04 {
  border-collapse: separate;
  border-spacing: 1px;
  text-align: left;
  line-height: 1.5;
  border-top: 1px solid #ccc;
  margin : 20px 10px;
}
table.type04 th {
  padding: 10px;
  font-weight: bold;
  vertical-align: top;
  border-bottom: 1px solid #ccc;
  text-align: center;
}
table.type04 td {
  padding: 10px;
  vertical-align: top;
  border-bottom: 1px solid #ccc;
}
.tdBlue{
    color: #00a0ee;
}
.tdRed{
    color: red;
}